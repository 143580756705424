import styled from 'react-emotion'

import t from 'src/theme'
import { ReactComponent as AssetTextureTimelineDottedLineSVG } from 'src/assets/texture-timeline-dotted-line.svg'

export const Container = styled.div`
  margin-bottom: ${t.s(2)};
`

export const DottedLine = styled(AssetTextureTimelineDottedLineSVG)`
  display: block;
  margin: 0 auto;
  transform: scaleX(${p => (p.direction === 'rightToLeft' ? -1 : 1)});
  width: 75%;
`
