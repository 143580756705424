import React from 'react'

import { TapeHeading } from 'src/components/Shared/TapeHeading/TapeHeading'
import { Image } from 'src/components/Shared/Image'
import {
  Container,
  Content,
  ImageContainer,
  StyledTimelineDottedLine,
} from './TimelineItem.styled'

export const TimelineItem = ({
  imageFluid,
  imageAlt,
  year,
  side = 'left',
  children,
  appendDottedLine = true,
  ...props
}) => (
  <>
    <Container side={side} {...props}>
      <Content side={side}>
        {year && <TapeHeading>{year}</TapeHeading>}
        {children}
      </Content>
      {imageFluid && (
        <ImageContainer side={side}>
          <Image fluid={imageFluid} alt={imageAlt} />
        </ImageContainer>
      )}
    </Container>
    {appendDottedLine && (
      <StyledTimelineDottedLine
        direction={side === 'left' ? 'leftToRight' : 'rightToLeft'}
      />
    )}
  </>
)
