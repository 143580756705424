import styled from 'react-emotion'

import t from 'src/theme'
import { TimelineDottedLine } from 'src/components/AboutUs/TimelineDottedLine/TimelineDottedLine'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${t.s(3)};

  &:last-child {
    margin-bottom: 0;
  }

  ${t.mq.m} {
    align-items: center;
    flex-direction: row;
    justify-content: ${p => (p.side === 'left' ? 'flex-start' : 'flex-end')};
    margin-bottom: ${t.s(0)};
  }
`

export const Content = styled.div`
  order: 2;
  text-align: center;

  ${t.mq.m} {
    margin-right: ${p => (p.side === 'left' ? t.s(2) : 0)};
    margin-left: ${p => (p.side === 'left' ? 0 : t.s(2))};
    order: ${p => (p.side === 'left' ? 1 : 2)};
    width: 25%;
  }

  h3 {
    color: ${t.c.lightGreen};
    font-family: ${t.ff.sansHeadline};
    font-size: ${t.f(2)};
    font-weight: bold;
    line-height: ${t.lh.title};
    margin-bottom: ${t.s(0)};
    text-align: center;
    text-transform: uppercase;
  }
`

export const ImageContainer = styled.div`
  max-width: ${t.s(8)};
  width: 100%;
  margin: 0 auto ${t.s(0)};
  order: 1;

  ${t.mq.m} {
    margin: 0;
    max-width: none;
    order: ${p => (p.side === 'left' ? 2 : 1)};
    width: 45%;
  }
`

export const StyledTimelineDottedLine = styled(TimelineDottedLine)`
  display: none;

  ${t.mq.m} {
    display: block;
  }
`
