import styled from 'react-emotion'
import t from 'src/theme'

export const Container = styled.div`
  background-color: ${t.c.lightCream};
  color: ${t.c.darkGray};
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-0.5)};
  line-height: ${t.lh.wide};
  padding: ${t.s(2)};
  position: relative;

  ${t.mq.m} {
    padding: ${t.s(5, 2)};
  }

  h1 {
    color: ${t.c.green};
    font-family: ${t.ff.sansHeadline};
    font-size: ${t.f(2)};
    margin-bottom: ${t.s(1)};

    ${t.mq.m} {
      font-size: ${t.f(3)};
    }
  }
`
