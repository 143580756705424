import styled from 'react-emotion'
import t from 'src/theme'

export const Container = styled.div`
  color: ${t.c.gray};
  display: inline-block;
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-0.5)};
  font-weight: 500;
  letter-spacing: 0.1em;
  padding: ${t.s(-3, 1)};
  position: relative;
  text-transform: uppercase;
`

export const Tape = styled.div`
  background-color: ${p => t.c[p.color]};
  content: '';
  display: block;
  position: absolute;
  transform: rotate(${p => `${p.rotation}deg`});
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
`

export const Content = styled.div`
  position: relative;
`
