import React from 'react'
import { random } from 'lodash'

import { Container, Content, Tape } from './TapeHeading.styled'

export const TapeHeading = ({
  children,
  color = 'pink',
  maxTilt = 3,
  ...props
}) => (
  <Container {...props}>
    <Tape
      color={color}
      rotation={random(-Math.abs(maxTilt), Math.abs(maxTilt))}
    />
    <Content>{children}</Content>
  </Container>
)
