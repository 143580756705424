import React from 'react'

import { LargeContentContainer } from 'src/components/Layout/Structure'
import { Container } from './PinkBox.styled'

export const PinkBox = ({ children, ...props }) => (
  <Container {...props}>
    <LargeContentContainer>{children}</LargeContentContainer>
  </Container>
)
