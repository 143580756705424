import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { get, map, size } from 'lodash'

import { Layout } from 'src/components/Layout/Layout'
import { LargeContentContainer } from 'src/components/Layout/Structure'
import { PinkBox } from 'src/components/Shared/PinkBox/PinkBox'
import { CreamBox } from 'src/components/Shared/CreamBox/CreamBox'
import { Timeline } from 'src/components/AboutUs/Timeline/Timeline'
import { TimelineItem } from 'src/components/AboutUs/TimelineItem/TimelineItem'
import { Image } from 'src/components/Shared/Image'
import { HeroContainer } from './_about-us.styled'

export const AboutUsPage = ({ data }) => {
  const page = get(data, 'prismicAboutUsPage')
  return (
    <Layout activePageName="About Us">
      <Helmet title={get(page, 'data.title.text')} />
      <HeroContainer>
        <Image
          alt={get(page, 'data.hero_image.alt')}
          fluid={get(page, 'data.hero_image.fluid')}
          fadeIn={false}
        />
      </HeroContainer>
      <PinkBox>
        <LargeContentContainer
          dangerouslySetInnerHTML={{
            __html: get(data, 'prismicAboutUsPage.data.intro.html'),
          }}
        />
      </PinkBox>
      <CreamBox>
        <Timeline>
          {map(get(page, 'data.timeline'), (item, index) => (
            <TimelineItem
              key={get(item, 'year')}
              imageFluid={get(item, 'image.fluid')}
              imageAlt={get(item, 'image.alt')}
              year={get(item, 'year')}
              side={index % 2 === 0 ? 'left' : 'right'}
              appendDottedLine={index !== size(get(page, 'data.timeline')) - 1}
            >
              <div
                dangerouslySetInnerHTML={{ __html: get(item, 'text.html') }}
              />
            </TimelineItem>
          ))}
        </Timeline>
      </CreamBox>
    </Layout>
  )
}

export default AboutUsPage

export const query = graphql`
  query {
    prismicAboutUsPage {
      data {
        title {
          text
        }
        hero_image {
          alt
          fluid(maxWidth: 1600) {
            ...GatsbyPrismicImageFluid
          }
        }
        intro {
          html
        }
        timeline {
          year
          text {
            html
          }
          image {
            alt
            fluid(maxWidth: 1000) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`
