import React from 'react'

import {
  LargeContentContainer,
  ExtraLargeContentContainer,
} from 'src/components/Layout/Structure'
import { Container } from './CreamBox.styled'

const variants = {
  wide: ExtraLargeContentContainer,
  default: LargeContentContainer,
}

export const CreamBox = ({
  children,
  contain = true,
  variant = 'default',
  ...props
}) => {
  const ContentContainer = variants[variant] || LargeContentContainer

  return (
    <Container {...props}>
      {contain ? <ContentContainer>{children}</ContentContainer> : children}
    </Container>
  )
}
