import styled from 'react-emotion'
import t from 'src/theme'

export const HeroContainer = styled.div`
  height: ${t.s(8)};

  .gatsby-image-wrapper,
  img {
    height: 100%;
  }

  ${t.mq.m} {
    height: ${t.s(9.5)};
  }
`
